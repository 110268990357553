import { ReactComponent as Check } from '@jetshop/ui/svg/Check.svg';
import { css } from 'linaria';
import React from 'react';
import { ActiveCarot } from './ActiveCarot';
import { filterName } from './shared/styles';
import { useListFilter } from '@jetshop/core/hooks/Filters/useListFilter';
import { useMultiFilter } from '@jetshop/core/hooks/Filters/useMultiFilter';

export const filtersStyle = css`
  list-style: none;
  li button {
    padding: 1em;
    width: 100%;
    display: flex;
    background: transparent;
    border-top: 1px solid white;
    background: #f0f0f0;
    justify-content: space-between;
    align-items: center;
    span {
      font-size: 0.75em;
    }
  }
`;

export const ListAccordion = ({
  filters,
  accordionProps,
  startingIdx = 0,
  onCarBrandChange // Callback prop for handling car brand change
}) => {
  if (!filters) return null;

  const listFilters = filters.filter(
    filter => filter.__typename === 'ListFilter' && filter.name !== 'carCode'
  );

  const multiListFilters = filters.filter(
    filter => filter.__typename === 'MultiListFilter'
  );

  return (
    <>
      {multiListFilters.map((filter, idx) => (
        <MultiListFilter
          key={filter.id}
          filter={filter}
          idx={idx}
          startingIdx={startingIdx}
          accordionProps={accordionProps}
          onCarBrandChange={onCarBrandChange} // Pass the callback prop to the child component
        />
      ))}
      {listFilters.map((filter, idx) => (
        <ListFilter
          key={filter.id}
          filter={filter}
          idx={idx}
          startingIdx={startingIdx}
          accordionProps={accordionProps}
          onCarBrandChange={onCarBrandChange} // Pass the callback prop to the child component
        />
      ))}
    </>
  );
};

function ListFilter({
  filter,
  idx,
  accordionProps,
  startingIdx,
  onCarBrandChange
}) {
  const { apply } = useListFilter({ filter });

  const { AccordionContent } = accordionProps;
  const accordionIsOpen = accordionProps.openIndexes.includes(
    idx + startingIdx
  );

  const handleCarBrandChange = () => {
    if (onCarBrandChange) {
      onCarBrandChange(); // Call the callback function passed as prop
    }
  };

  return (
    <>
      <div
        className={filterName}
        onClick={() => {
          accordionProps.handleClick(idx);
          handleCarBrandChange(); // Call the callback function when filter is clicked
        }}
      >
        {filter.name}
        <ActiveCarot
          isActive={filter.hasActiveItems}
          isOpen={accordionIsOpen}
        />
      </div>
      <AccordionContent isOpen={accordionIsOpen}>
        <ul className={filtersStyle}>
          {filter.items.map(item => (
            <li key={item.id}>
              <button
                onClick={() => {
                  apply({ value: item.value });
                  handleCarBrandChange(); // Call the callback function when filter is clicked
                }}
              >
                {item.text}

                {item.isActive ? <Check /> : <span>{item.resultCount}</span>}
              </button>
            </li>
          ))}
        </ul>
      </AccordionContent>
    </>
  );
}

function MultiListFilter({
  filter,
  idx,
  accordionProps,
  startingIdx,
  onCarBrandChange
}) {
  const { apply, listIndex } = useMultiFilter({ filter });

  const { AccordionContent } = accordionProps;

  const handleCarBrandChange = () => {
    if (onCarBrandChange) {
      onCarBrandChange(); // Call the callback function passed as prop
    }
  };

  return (
    <>
      {filter.lists.map((item, index) => {
        const accordionIsOpen = accordionProps.openIndexes.includes(item.id);
        return (
          <>
            <div
              className={filterName}
              onClick={() => {
                accordionProps.handleClick(item.id);
                handleCarBrandChange(); // Call the callback function when filter is clicked
              }}
            >
              <>
                {item.name}
                <ActiveCarot
                  isActive={item.hasActiveItems}
                  isOpen={accordionIsOpen}
                />
              </>
            </div>
            <AccordionContent isOpen={accordionIsOpen}>
              <ul className={filtersStyle}>
                {item.items.map(
                  ({ id, text, value, isActive, resultCount }) => (
                    <li key={id}>
                      <button
                        onClick={() => {
                          apply({ value: value, listIndex: index });
                          handleCarBrandChange(); // Call the callback function when filter is clicked
                        }}
                      >
                        {text}
                        {isActive ? <Check /> : <span>{resultCount}</span>}
                      </button>
                    </li>
                  )
                )}
              </ul>
            </AccordionContent>
          </>
        );
      })}
    </>
  );
}
