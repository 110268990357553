import React from 'react';
import Image from '@jetshop/ui/Image/Image';
import { searchFieldStyles } from '../../../components/SearchPage/SearchField';
import { Above, Below } from '@jetshop/ui/Breakpoints';
import AutocompleteQuery from '../../../components/Layout/Header/AutocompleteQuery.gql';
import useCleanProps from '../../../hooks/useCleanProps';
import Styled from './Style';

import { ReactComponent as Location } from '../../../assets/svg/Location.svg';
import HeroSearchForm from './HeroSearchForm';
import { RegNrProvider } from './Context';

const useChoppedUpTitle = informationHeader => {
  const [first, second, ...rest] = informationHeader.split(' ');

  return [first, second, rest.join(' ')];
};

const HeroBlock = props => {
  const {
    informationHeader,
    informationText,
    backgroundImage,
    regInputPlaceholder,
    searchInputPlaceholder
  } = useCleanProps(props);
  const [first, second, rest] = useChoppedUpTitle(informationHeader);

  return (
    <Styled.HeaderWrapper>
      <Styled.MaxWidth>
        <Styled.RegNrContainer>
          <Styled.ImageContainer>
            <Image
              src={backgroundImage}
              aspect="115:43"
              fillAvailableSpace={true}
            />
          </Styled.ImageContainer>

          <Styled.InnerContainer>
            {/* <Below breakpoint="sm">
              <Styled.LinkWrapper>
                <Location className={Styled.location} />
                <Styled.ExternalLink
                  href={
                    'https://garage.recopart.se/allabildelarverkstad/#/login'
                  }
                  target={'_blank'}
                  rel="noreferrer"
                >
                  Verkstadskund
                </Styled.ExternalLink>
              </Styled.LinkWrapper>
            </Below> */}
            <Below breakpoint="md">
              <Styled.TextContainerMobile>
                <Styled.TitleMobile>
                  {first} <span>{second}</span> {rest}
                </Styled.TitleMobile>
              </Styled.TextContainerMobile>
            </Below>
            <Styled.InfoText>{informationText}</Styled.InfoText>
            <RegNrProvider>
              <HeroSearchForm
                className={searchFieldStyles}
                regInputPlaceholder={regInputPlaceholder}
                searchInputPlaceholder={searchInputPlaceholder}
                autocompleteQuery={AutocompleteQuery}
              />
            </RegNrProvider>
          </Styled.InnerContainer>
          <Above breakpoint="md">
            <Styled.TextContainer>
              <Styled.Title>
                {first} <span>{second}</span> {rest}
              </Styled.Title>
            </Styled.TextContainer>
          </Above>
        </Styled.RegNrContainer>
      </Styled.MaxWidth>
    </Styled.HeaderWrapper>
  );
};

export default HeroBlock;
