import React from 'react';
import ReactSelect from 'react-select';
import { styled } from 'linaria/react';
import { theme } from '../Theme';

const inputStyle = {
  fontSize: '14px',
  textTransform: 'uppercase',
  fontFamily: theme.fonts.primary,
  fontWeight: 700,
  color: theme.colors.black,
  letterSpacing: '0.05em'
};

const colorStyles = {
  control: styles => ({
    ...styles,
    backgroundColor: theme.colors.white,
    borderRadius: '0',
    border: `none`,
    // minHeight: '44px',
    minWidth: '200px',
    width: 'auto',
    maxWidth: '100%',
    boxShadow: 'none',
    '&:active, &:focus, &:hover': {
      borderColor: 'inherit'
    }
  }),
  valueContainer: styles => ({
    ...styles,
    paddingLeft: '0rem'
  }),
  placeholder: styles => ({
    ...styles,
    ...inputStyle
  }),
  singleValue: styles => ({
    ...styles,
    ...inputStyle
  }),
  menu: styles => ({
    ...styles,
    borderRadius: 0,
    marginTop: 0,
    border: `1px solid ${theme.colors.black}`
  }),
  menuList: styles => ({
    ...styles,
    padding: 0
  }),
  dropdownIndicator: styles => ({ ...styles, color: theme.colors.black }),
  indicatorSeparator: () => ({ display: 'none' }),
  option: (styles, { isSelected }) => ({
    ...styles,
    backgroundColor: 'inherit',
    color: 'inherit',
    paddingLeft: '1rem',
    '&:hover': {
      backgroundColor: theme.colors.abdMediumGrey
    }
  })
};

const SimpleSelectWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const SimpleSelectLabel = styled.span`
  font-size: 14px;
`;

const SimpleSelect = ({ label, ...rest }) => (
  <SimpleSelectWrapper>
    <SimpleSelectLabel>{label}</SimpleSelectLabel>
    <ReactSelect styles={colorStyles} {...rest} />
  </SimpleSelectWrapper>
);

export default SimpleSelect;
