import React from 'react';
import { styled } from 'linaria/react';
import { theme } from '../../components/Theme';
import { ReactComponent as ArrowRight } from '../../assets/svg/ArrowRight.svg';
import { ReactComponent as Accent } from '../../assets/svg/Accent.svg';
import { Link } from 'react-router-dom';
import { Below } from '@jetshop/ui/Breakpoints';
import Image from '@jetshop/ui/Image/Image';

const ArticleContainer = styled(Link)`
  text-decoration: none;

  :link,
  :visited {
    color: inherit;
  }

  :hover,
  :active {
    color: inherit;
    box-shadow: rgba(0, 0, 0, 0.18) 0px 3px 8px;
  }

  width: 450px;
  max-height: 468px;
  border: 1px solid #dfdfdf;

  ${theme.below.xl} {
    width: 389px;
  }
  ${theme.below.lg} {
    width: 48%;
  }
  ${theme.below.md} {
    width: 300px;
  }
`;

const ImgContainer = styled('div')`
  width: auto;
  height: auto;

  img {
    max-width: 100%;
    max-height: 100%;
  }
`;

const Icon = styled('div')`
  -webkit-background-size: cover;
  background-size: cover;

  height: 100%;
  width: 100%;
  display: block;
`;

const ArticleInfoWrapper = styled('div')`
  padding: 10px;
  max-height: 248px;
  position: relative;
`;

const Title = styled('div')`
  font-weight: 800;
  font-size: 20px;
  line-height: 140%;
  height: 50px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 12px;
  padding-bottom: 20px;
`;

const TextReadContainer = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 18px;
`;

const Text = styled('div')`
  font-weight: 500;
  font-size: 16px;
  line-height: 140%;
  height: 126px;
`;

const NavLink = styled('div')`
  font-weight: 700;
  font-size: 16px;
  line-height: 140%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 14px;
`;

const Content = ({
  title,
  text,
  linkLabelAlt,
  linkUrlAlt,
  backgroundImage
}) => {
  const shortTextString = text?.replace(/(.{130})..+/, '$1…');
  return (
    <ArticleContainer to={linkUrlAlt}>
      <ImgContainer>
        <Icon>
          <Image aspect="2:1" cover src={backgroundImage} />
        </Icon>
      </ImgContainer>
      <ArticleInfoWrapper>
        {/* {title && (
          <Title>
            {title}
            <Accent />
          </Title>
          
        )} */}
        <NavLink>
          {linkLabelAlt}
          <ArrowRight />
        </NavLink>
        {/* <TextReadContainer>
          <Below breakpoint="xl">
            {matches =>
              matches ? <Text>{shortTextString}</Text> : <Text>{text}</Text>
            }
          </Below>
          <NavLink>
            {linkLabel}
            <ArrowRight />
          </NavLink>
        </TextReadContainer> */}
      </ArticleInfoWrapper>
    </ArticleContainer>
  );
};

export default Content;
