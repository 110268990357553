import throwErrorInDev from '@jetshop/core/helpers/throwErrorInDev';
import t from '@jetshop/intl';
import { FlyoutTarget, FlyoutTrigger } from '@jetshop/ui/Modal/Flyout';
import SuggestedTerm from '@jetshop/ui/Search/SuggestedTerm';
import React, { useState } from 'react';
import { styled } from 'linaria/react';
import { css } from 'linaria';
import { Link } from 'react-router-dom';
import SearchAutoCompleteContainer from './SearchAutoCompleteContainer';
import { theme } from '../Theme';
import { getSearchParamsBasedOnCategoryId } from '../../helpers';
import { ReactComponent as VerificationCheck } from '../../assets/svg/VerificationCheck.svg';

export const searchFieldStyles = css`
  margin: 0;

  ${theme.above.md} {
    width: 300px;
  }

  ${theme.below.md} {
    flex: 1;
  }

  &[data-flight-searchfield] > * {
    display: flex;
    height: 100%;
    width: 100% !important;
    justify-content: center;

    input {
      font-family: 'Barlow';
      font-weight: 700;
      font-size: 0.875rem;
      line-height: 120%;
      color: #000000 !important;
      height: 100%;
      width: 100%;
      padding: 0 1rem;
      outline: none;

      ::placeholder {
        text-transform: uppercase;
      }
    }
  }
  [data-flight-searchfield-cancel] {
    display: none;
  }
  [data-flight-searchfield-flyout] {
    text-align: left;
    top: 100%;

    > * {
      padding: 0.5rem;
    }
    h2 {
      color: #999999;
      text-transform: uppercase;
      font-size: 0.75rem;
      padding: 0.5rem;
    }
    li {
      border-top: 1px solid #e8e8e8;
    }
    a {
      color: black;
      padding: 0.5rem;
      display: block;
    }
  }
`;

const Wrapper = styled('div')`
  display: flex;
  margin: 0 auto;
  justify-content: center;
  align-items: center;
`;

const SearchFieldWrapper = styled('div')`
  width: 12rem;
  position: relative;
  font-size: 16px;
  font-weight: normal;
`;

const Flyout = styled('div')`
  position: absolute;
  background: white;
  z-index: 999;
  width: 100%;
  top: 2rem;
  left: 0;
`;

const CancelButton = styled('button')`
  font-size: 0.875rem;
  border: 0;
  background: transparent;
  color: #878787;
  margin-left: 1rem;
`;

const resultStyles = css`
  ul + h2 {
    margin-top: 1em;
  }
`;

const CategoryLink = styled(Link)``;

const verificationCheck = css`
  position: absolute;
  right: 5px;
  top: 15px;
`;

const SearchResults = ({
  term,
  result,
  loading,
  onClick,
  filtersFromLocationState
}) => {
  const products = result?.products;
  const categories = result?.categories;

  if (
    !loading &&
    products?.result.length === 0 &&
    categories?.result.length === 0
  ) {
    return null;
  }

  return (
    <div className={resultStyles}>
      {products?.result.length > 0 && (
        <>
          <h2>{t('Products')}</h2>
          <ul style={loading ? { opacity: 0.5 } : null}>
            {products.result.map(item => (
              <SuggestedTerm
                term={term}
                onClick={onClick}
                key={item.id}
                item={item}
              />
            ))}
          </ul>
        </>
      )}

      {categories?.result.length > 0 && (
        <>
          <h2>{t('Categories')}</h2>
          <ul style={loading ? { opacity: 0.5 } : null}>
            {categories.result.map(item => {
              const searchParams = getSearchParamsBasedOnCategoryId(item);
              return (
                <li>
                  <CategoryLink
                    to={{
                      pathname: `/${item.primaryRoute.id}${searchParams}`,
                      state: {
                        recopartCarModel: filtersFromLocationState
                      }
                    }}
                  >
                    {item.name}
                  </CategoryLink>
                </li>
              );
            })}
          </ul>
        </>
      )}
    </div>
  );
};

const SearchField = ({
  initialValue,
  className,
  placeholder = '',
  autocompleteQuery,
  filtersFromLocationState,
  carData,
  ...rest
}) => {
  throwErrorInDev(
    !autocompleteQuery,
    `You forgot to pass 'autocompleteQuery' to SearchField`
  );
  return (
    <SearchAutoCompleteContainer
      autocompleteQuery={autocompleteQuery}
      initialValue={initialValue}
      carData={carData}
      filtersFromLocationState={filtersFromLocationState}
      {...rest}
    >
      {searchField => (
        <Wrapper className={className} data-flight-searchfield="">
          <SearchFieldWrapper>
            <FlyoutTrigger showCover={false} id="searchFlyout">
              {({ showTarget, hideTarget }) => (
                <React.Fragment>
                  <input
                    placeholder={placeholder}
                    {...searchField.getInputProps({
                      onFocus: showTarget
                    })}
                    data-flight-search-autocomplete-field=""
                  />

                  <CancelButton
                    {...searchField.getCancelProps({ onClick: hideTarget })}
                    data-flight-searchfield-cancel
                  >
                    {t('Cancel')}
                  </CancelButton>
                </React.Fragment>
              )}
            </FlyoutTrigger>

            <FlyoutTarget id="searchFlyout">
              {flyout => {
                const hasResults =
                  searchField.result?.products?.result.length > 0 ||
                  searchField.result?.categories?.result.length > 0;
                const { loading, isDirty, isOpen } = searchField;

                const showFlyout =
                  isDirty && !loading && hasResults && isOpen && flyout.isOpen;

                if (showFlyout) {
                  return (
                    <Flyout data-flight-searchfield-flyout>
                      <div {...searchField.getFlyoutProps()}>
                        {searchField.isDirty && (
                          <React.Fragment>
                            <SearchResults
                              term={searchField.term}
                              result={searchField.result}
                              onClick={flyout.hideTarget}
                              loading={searchField.loading}
                              filtersFromLocationState={
                                filtersFromLocationState
                              }
                            />
                          </React.Fragment>
                        )}
                      </div>
                    </Flyout>
                  );
                }
                return null;
              }}
            </FlyoutTarget>
          </SearchFieldWrapper>
        </Wrapper>
      )}
    </SearchAutoCompleteContainer>
  );
};

SearchField.Flyout = Flyout;
SearchField.Wrapper = SearchFieldWrapper;
SearchField.Cancel = CancelButton;

export default SearchField;
