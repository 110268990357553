import React, { createContext, useContext, useReducer } from 'react';

const RegNrContext = createContext(null);

const RegNrDispatchContext = createContext(null);

export function RegNrProvider({ children }) {
  const [tasks, dispatch] = useReducer(regNrReducer, initialRegNr);

  return (
    <RegNrContext.Provider value={tasks}>
      <RegNrDispatchContext.Provider value={dispatch}>
        {children}
      </RegNrDispatchContext.Provider>
    </RegNrContext.Provider>
  );
}

export function useRegNrContext() {
  return useContext(RegNrContext);
}

export function useRegNrDispatch() {
  return useContext(RegNrDispatchContext);
}

function regNrReducer(currentState, action) {
  switch (action.type) {
    case 'addFilter': {
      return {
        ...currentState,
        id: action.filter.filterId,
        [action.filter.type]:
          currentState[action.filter.type].items.lenth > 0
            ? {
                ...currentState[action.filter.type],
                items: action.filter.items
              }
            : { ...currentState[action.filter.type], ...action.filter }
      };
    }
    case 'addActiveFilterItem': {
      return {
        ...currentState,
        [action.filter.type]: {
          ...currentState[action.filter.type],
          activeItem: action.filter.activeItem
        }
      };
    }
    case 'resetModel': {
      return {
        ...currentState,
        model: { ...initialRegNr.model }
      };
    }

    case 'resetManufacturer': {
      return {
        ...currentState,
        manufacturer: { ...initialRegNr.manufacturer }
      };
    }

    default: {
      throw Error('Unknown action: ' + action.type);
    }
  }
}

const initialRegNr = {
  id: null,
  manufacturer: {
    name: 'Tillverkare',
    type: 'manufacturer',
    items: [],
    activeItem: null
  },
  model: { name: 'Modell', type: 'model', items: [], activeItem: null }
};
