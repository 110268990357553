import Image from '@jetshop/ui/Image/Image';
import React from 'react';
import { styled } from 'linaria/react';
import MaxWidth from '../Layout/MaxWidth';
import { theme } from '../Theme';
import { CategoryBrands } from './CategoryBrands';
import { CategoryDescription } from './CategoryDescription';
import useRecopartSearch from '../../hooks/useRecopartSearch';
import recopartCarModelQuery from '../../gql/queries/RecopartCarModelQuery.gql';
import { useLocation, Redirect, useHistory } from 'react-router';
import { useQuery } from '@apollo/react-hooks';
import qs from 'qs';
import { useFiltersFromLocationState } from './WindowedCategoryPage';
import CarModelSpecification from './CarModelSpecification';
import HeroSearchForm from '../../elements/root/RegNrBlock/HeroSearchForm';
import HeroSearchFormAlt from '../../elements/root/RegNrBlock/HeroSearchFormAlt';
import AutocompleteQuery from '../../components/Layout/Header/AutocompleteQuery.gql';
import { searchFieldStyles } from '../../components/SearchPage/SearchField';
import { RegNrProvider } from '../../elements/root/RegNrBlock/Context';
import { SortOrders } from './Filters/SortOrders';

const CategoryImageWrapper = styled('div')`
  position: relative;
  width: 100%;

  ${theme.below.md} {
    height: 217px;
  }
`;

const SortOrdersWrapper = styled.div``;

const Overlay = styled('div')`
  background-color: rgba(0, 0, 0, 0.61);
  width: 100%;
  position: absolute;
  bottom: 0;
  display: flex;
  align-items: center;
`;

export const CategoryHeaderWrapper = styled('div')`
  background-color: #ffffff;

  ${Overlay} {
    position: ${props => (props.hasBackgroundImage ? 'absolute' : 'inherit')};
  }

  ${CategoryImageWrapper} {
    height: ${props => (props.hasBackgroundImage ? '416px' : 'auto')};
  }
`;

const ContentWrapper = styled('div')`
  display: flex;
  align-items: center;

  ${theme.below.xl} {
    flex-direction: column;
    align-items: start;
  }
`;

const CategoryName = styled('h1')`
  min-width: 100px;
  color: ${theme.colors.white};
  font-size: 1.5rem;
  padding: 10px 0;

  ${theme.below.sm} {
    margin-bottom: 2px;
  }
`;

const CarInfoContent = styled('div')``;

const CategoryHeader = ({
  category,
  backgroundImage,
  children,
  description
}) => {
  const carData = useFiltersFromLocationState();

  return (
    <CategoryHeaderWrapper hasBackgroundImage={backgroundImage}>
      {/* <CategoryImageWrapper>
        {backgroundImage && (
          <Image
            src={backgroundImage.value?.value}
            fillAvailableSpace
            cover
            quality={80}
            critical={true}
            sizes={[1]}
          />
        )}
        <Overlay>
          <MaxWidth>
            <ContentWrapper>
              <RegNrProvider>
                <HeroSearchFormAlt
                  className={searchFieldStyles}
                  regInputPlaceholder={carData?.licensePlateNumber}
                  searchInputPlaceholder=""
                  autocompleteQuery={AutocompleteQuery}
                />
              </RegNrProvider>
            </ContentWrapper>
            <CarModelSpecification carData={carData} />
          </MaxWidth>
        </Overlay>
      </CategoryImageWrapper> */}
      {<CategoryBrands brands={children} />}
      {<CategoryDescription description={description} />}
      <RegNrProvider>
        <HeroSearchFormAlt
          className={searchFieldStyles}
          regInputPlaceholder="Ange regnr"
          searchInputPlaceholder=""
          autocompleteQuery={AutocompleteQuery}
          sortOrders={category.products?.sortOrders || []}
          carData={carData}
        />
        {/* <SortOrdersWrapper>
          <SortOrders sortOrders={category.products?.sortOrders || []} />
        </SortOrdersWrapper> */}
      </RegNrProvider>
      {/* {<CategoryBrands brands={children} />}
      {<CategoryDescription description={description} />} */}
      {/* <CarModelSpecification carData={carData} /> */}
    </CategoryHeaderWrapper>
  );
};

export default CategoryHeader;
