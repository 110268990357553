import t from '@jetshop/intl';
import React, { useEffect } from 'react';
import { styled } from 'linaria/react';
import MaxWidth from '../Layout/MaxWidth';
import searchQuery from './SearchQuery.gql';
import { useApolloClient } from 'react-apollo';
import { getActiveFiltersFromSearch } from '@jetshop/core/resolvers/filters/filterParams';
import qs from 'qs';
import Drawer, { DrawerTarget, DrawerTrigger } from '@jetshop/ui/Modal/Drawer';
import { Above, Below } from '@jetshop/ui/Breakpoints';
import { TrendLink } from '../ui/Button';
import { useLocation } from 'react-router';
import useInfinitePagination from '@jetshop/core/hooks/useInfinitePagination';
import PaginationContext from '@jetshop/core/components/Pagination/PaginationContext';
import { CategoryList } from '../CategoryPage/CategoryListing';
import { theme } from '../Theme';
import ProductGrid from '../CategoryPage/ProductGrid';
import { ActiveFilters } from '../CategoryPage/Filters/ActiveFilters';
import ClearButton from '../CategoryPage/Filters/ClearButton';
import { useFilters } from '@jetshop/core/hooks/Filters/useFilters';
import CategoryButton from '../CategoryPage/Filters/Mobile/CategoryButton';
import CategoryDrawer from '../CategoryPage/Filters/CategoryDrawer';
import CategoryHeaderBanner from '../CategoryPage/CategoryHeaderBanner';
import SearchInputContainer from './SearchInputContainer';
import { RegNrProvider } from '../../elements/root/RegNrBlock/Context';
import HeroSearchFormAlt from '../../elements/root/RegNrBlock/HeroSearchFormAlt';
import { searchFieldStyles } from '../../components/SearchPage/SearchField';
import AutocompleteQuery from '../../components/Layout/Header/AutocompleteQuery.gql';
import Filters from '../CategoryPage/Filters';

const Matches = styled('div')`
  display: flex;
  text-align: center;
  color: gray;
  padding: 1rem 0;

  ${theme.below.lg} {
    display: none;
  }
`;

const Container = styled(MaxWidth)`
  padding-top: 2rem;
  gap: 4rem;

  ${theme.above.sm} {
    padding-top: 2rem;
  }
`;

const ButtonWrapper = styled('div')`
  width: 100%;
  max-width: 16rem;
  margin: 1rem auto;
`;

const MenuButtonWrapper = styled('div')`
  display: flex;
  margin-bottom: 10px;
  grid-gap: 10px;

  ${theme.below.sm} {
    flex-wrap: wrap;
  }
`;

const Wrapper = styled('div')``;

const SideBar = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  position: relative;
`;

const MainContent = styled.div``;

const ActiveFiltersContainer = styled('div')`
  display: flex;
  justify-content: space-between;
  padding: 1rem 0;

  ${theme.below.md} {
    flex-direction: column;
    grid-gap: 10px;
  }
`;

function useFiltersOnHistoryPop() {
  const client = useApolloClient();

  useEffect(() => {
    function getSearchFilters() {
      // Write the active filters from search
      client.writeData({
        data: {
          activeFilters: getActiveFiltersFromSearch(window.location.search)
        }
      });
    }

    getSearchFilters();
  }, [client]);
}
const WindowedSearchResults = ({ loading, ...props }) => {
  const { total, term, result } = props;
  const { clearAllFilters } = useFilters();

  const { currentPage } = React.useContext(PaginationContext);
  const search = result?.data?.search;
  const location = useLocation();
  const params = qs.parse(location.search, { ignoreQueryPrefix: true });

  const { products, previous, next } = useInfinitePagination({
    result,
    query: searchQuery
  });
  const categoryFilter = result.data?.search?.products?.filters.find(
    filter => filter.id === 'categories'
  );

  const activeListFilters = result.data?.search?.products?.filters.filter(
    listFilter => {
      return listFilter.hasActiveItems && listFilter.name !== 'carCode';
    }
  );

  useFiltersOnHistoryPop();

  return (
    <Wrapper>
      <CategoryHeaderBanner />
      <Container>
        {/* <Above breakpoint="lg">
          <SideBar>
            <SearchInputContainer />
            <CategoryList filter={categoryFilter} />
          </SideBar>
        </Above> */}
        <MainContent>
          <Matches>
            {loading ? (
              currentPage === 1 ? (
                <div>
                  {t(`Loading search results for {term}…`, {
                    term
                  })}
                </div>
              ) : (
                <div>
                  {t(`Loading page {currentPage}…`, {
                    currentPage
                  })}
                </div>
              )
            ) : (
              <div>
                {t(
                  '{total, plural, =1 {# item} other {# items}} matching for "{term}"',
                  {
                    total,
                    term
                  }
                )}
              </div>
            )}
          </Matches>
          <Above breakpoint="md">
            <Filters
              filters={result.data?.search?.products?.filters || []}
              sortOrders={result.data?.search?.products?.filters || []}
              result={result}
            />
          </Above>
          <Below breakpoint="md">
            {matches =>
              matches && (
                <MenuButtonWrapper>
                  <DrawerTrigger id="categoryDrawer">
                    {({ showTarget }) => (
                      <>
                        <CategoryButton
                          showTarget={showTarget}
                          filters={
                            result.data?.search?.products?.filters?.filters ||
                            []
                          }
                        />
                        <DrawerTarget id="categoryDrawer">
                          {({ hideTarget, isOpen }) => (
                            <Drawer isOpen={isOpen} right>
                              <CategoryDrawer
                                close={hideTarget}
                                filters={
                                  result.data?.search?.products?.filters || []
                                }
                                currentCategory={null}
                                as="filterListing"
                              />
                            </Drawer>
                          )}
                        </DrawerTarget>
                      </>
                    )}
                  </DrawerTrigger>
                  <SearchInputContainer />
                </MenuButtonWrapper>
              )
            }
          </Below>
          {/* {!!activeListFilters?.length > 0 && (
            <ActiveFiltersContainer>
              <div>
                <span>Sökning filtrerad på:</span>

                <ActiveFilters filters={search?.products?.filters || []} />
              </div>
              <ClearButton type="button" onClick={clearAllFilters}>
                {t('Clear all filters')}
              </ClearButton>
            </ActiveFiltersContainer>
          )} */}
          {previous.hasProducts && (
            <ButtonWrapper>
              <TrendLink
                secondary
                onClick={e => {
                  e.preventDefault();
                  previous.fetchProducts();
                }}
                to={{
                  ...location,
                  search: qs.stringify({
                    ...params,
                    page: previous.page
                  })
                }}
              >
                {t('Show more')}
              </TrendLink>
            </ButtonWrapper>
          )}
          <ProductGrid
            id="search"
            prevOffset={previous.offset}
            products={products}
            listName={'search'}
            loading={result.loading}
            imageSizes={[1 / 2, 1 / 2, 1 / 3, 1 / 4]}
          />
          {next.hasProducts && (
            <ButtonWrapper>
              <TrendLink
                secondary
                onClick={e => {
                  e.preventDefault();
                  next.fetchProducts();
                }}
                to={{
                  ...location,
                  search: qs.stringify({
                    ...params,
                    page: next.page
                  })
                }}
              >
                {t('Show more')}
              </TrendLink>
            </ButtonWrapper>
          )}
        </MainContent>
      </Container>
    </Wrapper>
  );
};

export default WindowedSearchResults;
