import React from 'react';
import t from '@jetshop/intl';
import MaxWidth from '../Layout/MaxWidth';
import CarModelSpecification from './CarModelSpecification';
import { theme } from '../Theme';
import { styled } from 'linaria/react';
import { useFiltersFromLocationState } from '../CategoryPage/WindowedCategoryPage';
import { RegNrProvider } from '../../elements/root/RegNrBlock/Context';
import HeroSearchFormAlt from '../../elements/root/RegNrBlock/HeroSearchFormAlt';
import { searchFieldStyles } from '../../components/SearchPage/SearchField';
import AutocompleteQuery from '../../components/Layout/Header/AutocompleteQuery.gql';
import HeroSearchFormAlt2 from '../../elements/root/RegNrBlock/HeroSearchFormAlt2';

export const CategoryHeaderWrapper = styled('div')`
  background-color: ${theme.colors.background};

  ${Overlay} {
    position: ${props => (props.hasBackgroundImage ? 'absolute' : 'inherit')};
    background-color: white;
    margin-top: 20px;
  }

  ${CategoryWrapper} {
    height: ${props => (props.hasBackgroundImage ? '416px' : 'auto')};
  }
`;

const CategoryWrapper = styled('div')`
  position: relative;
  width: 100%;
  gap: 0.5rem 1.5rem;

  ${theme.below.md} {
    height: 217px;
  }
`;

const CategoryName = styled('h1')`
  min-width: 120px;
  color: ${theme.colors.white};
  font-size: 1.5rem;
  padding: 10px 0;

  ${theme.below.sm} {
    margin-bottom: 2px;
  }
`;

const Overlay = styled('div')`
  background-color: rgba(0, 0, 0, 0.61);
  width: 100%;
  position: absolute;
  bottom: 0;
  display: flex;
  align-items: center;
`;

const ContentWrapper = styled('div')`
  display: flex;
  align-items: center;
  ${theme.below.xl} {
    flex-direction: column;
    align-items: start;
  }
`;

const CarInfoContent = styled('div')``;

const CategoryHeaderBanner = () => {
  const carData = useFiltersFromLocationState();

  return (
    <CategoryHeaderWrapper>
      <CategoryWrapper>
        <Overlay>
          <MaxWidth>
            <ContentWrapper>
              {/* Hide functionality for now... */}
              {/* <RegNrProvider>
                <HeroSearchFormAlt2
                  className={searchFieldStyles}
                  regInputPlaceholder="Ange regnr"
                  searchInputPlaceholder=""
                  autocompleteQuery={AutocompleteQuery}
                  licensePlateNumber={carData?.licensePlateNumber}
                />
              </RegNrProvider> */}
            </ContentWrapper>
            <CarModelSpecification carData={carData} />
          </MaxWidth>
        </Overlay>
      </CategoryWrapper>
    </CategoryHeaderWrapper>
  );
};

export default CategoryHeaderBanner;
