import React from 'react';
import { styled } from 'linaria/react';
import MaxWidth from '../Layout/MaxWidth';
import { theme } from '../Theme';
import { useState, useEffect, useRef } from 'react';
import useWindowSize from '../../hooks/useWindowSize';
import { useIntl } from '@jetshop/intl';

const descriptionHeight = 200;
const smallScreenHeight = 195;

const Footer = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;

  padding-left: 2rem;
  background-color: ${theme.colors.abdMediumGrey};

  ${theme.below.sm} {
    padding: 0rem 1rem;
  }

  &::after {
    content: '';
    display: block;
    position: absolute;
    bottom: 100%;
    left: 0;
    width: 100%;
    height: 77px;
    opacity: 1;
    transition: opacity 0.3s ease-in-out;
    background: linear-gradient(
      0deg,
      ${theme.colors.abdMediumGrey},
      transparent
    );
  }
`;

const CategoryDescriptionWrapper = styled(MaxWidth)`
  padding-top: ${theme.space[2]};
  padding-bottom: ${props =>
    props.contentOverflows ? '5.5rem' : theme.space[5]};
  max-height: ${props =>
    props.isExpanded ? 'auto' : `${descriptionHeight}px`};
  overflow: hidden;
  position: relative;
  font-family: ${theme.fonts.primary};
  letter-spacing: 0.04em;

  ${theme.below.sm} {
    max-height: ${props =>
      props.isExpanded
        ? 'auto'
        : `${smallScreenHeight}px`}; // Use smaller height on small screens
    padding-top: ${theme.space[2]};
    padding-bottom: ${props =>
      props.contentOverflows ? '2rem' : theme.space[5]};
  }

  ${Footer} {
    &::after {
      opacity: ${props => (props.isExpanded ? '0' : 'inherit')};
    }
  }
`;

const Content = styled.div`
  font-family: ${theme.fonts.primary};
  max-width: 1416px;
  h1 {
    font-size: 2.5rem;
    display: inline-block; /* Add this line */
    line-height: 1; /* Add line-height */
    margin-bottom: 1rem;
  }

  ${theme.below.sm} {
    padding-bottom: ${theme.space[3]};

    h1 {
      font-size: 2.5rem;
    }
  }
`;

const ReadMoreButton = styled.button`
  font-size: 16px;
  font-weight: 700;
  text-transform: uppercase;
  background: none;
  margin-bottom: 16px;

  ${theme.below.sm} {
    font-size: 0.85rem;
  }
`;

export const CategoryDescription = ({ description }) => {
  const { width } = useWindowSize();
  const [wrapperHeight, setWrapperHeight] = useState(0);
  const [contentHeight, setContentHeight] = useState(0);
  const [expanded, setExpanded] = useState(false);
  const [contentOverflows, setContentOverflows] = useState(false);

  const wrapperRef = useRef(null);
  const contentRef = useRef(null);

  const t = useIntl();

  useEffect(() => {
    setWrapperHeight(wrapperRef.current?.clientHeight);
    setContentHeight(contentRef.current?.clientHeight);
  }, [width]);

  useEffect(() => {
    setContentOverflows(contentHeight + 105 > descriptionHeight);
  }, [wrapperHeight, contentHeight]);

  if (!description) return null;

  return (
    <CategoryDescriptionWrapper
      ref={wrapperRef}
      isExpanded={expanded}
      contentOverflows={contentOverflows}
    >
      <Content
        ref={contentRef}
        dangerouslySetInnerHTML={{ __html: description.value }}
      />
      {contentOverflows && (
        <Footer>
          <ReadMoreButton onClick={() => setExpanded(!expanded)}>
            {expanded ? t('Show less') : t('Show more')}
          </ReadMoreButton>
        </Footer>
      )}
    </CategoryDescriptionWrapper>
  );
};
