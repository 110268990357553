import { useIntl } from '@jetshop/intl';
import React from 'react';
import { styled } from 'linaria/react';
import { useFilters } from '@jetshop/core/hooks/Filters/useFilters';
import ClearButton from './ClearButton';
import Button from '../../ui/Button';
import { CategoryList } from '../../../components/CategoryPage/CategoryListing';
import { ReactComponent as CrossIcon } from '@jetshop/ui/svg/Cross.svg';

const Title = styled('h1')`
  color: black;
  font-size: 1.125rem;
  text-align: center;
  padding: 1.5rem;
  border-bottom: 1px solid #e8e8e8;
  font-weight: 600;
`;

const Wrapper = styled('div')``;

const FlyoutDrawerHeader = styled('div')`
  z-index: 1;
  position: sticky;
  top: 0;
  background: white;
`;

const ButtonContainer = styled('div')`
  position: sticky;
  bottom: 0;
  padding: 1em;
  background: white;
  > button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    border-radius: 0;
    height: 36px;

    &:last-of-type {
      margin: 0 0 0 0.5em;
    }

    &:first-of-type {
      margin: 0 0.5em 0 0;
    }
  }
`;

const CloseButton = styled('button')`
  background: transparent;
  position: absolute;
  top: 0;
  right: 10px;
  padding: 10px;
  path {
    fill: black;
  }
`;

const CategoryDrawer = ({ as, filters, sortOrders, close, ...props }) => {
  const t = useIntl();

  const { clearAllFilters } = useFilters();
  const isActiveListFilter = filters.some(listFilter => {
    return listFilter.hasActiveItems;
  });

  const categoryFilter = filters?.find(filter => filter.id === 'categories');

  return (
    <section {...props}>
      <>
        <Wrapper>
          <FlyoutDrawerHeader>
            <CloseButton onClick={close}>
              <CrossIcon />
            </CloseButton>
          </FlyoutDrawerHeader>
          <CategoryList onClick={close} filter={categoryFilter} />
        </Wrapper>

        <ButtonContainer>
          <Button onClick={close}>{t('Apply filters')}</Button>
          {isActiveListFilter && (
            <ClearButton type="button" onClick={clearAllFilters}>
              {t('Clear all filters')}
            </ClearButton>
          )}
        </ButtonContainer>
      </>
    </section>
  );
};
export default CategoryDrawer;
