import React from 'react';
import Styled from '../SearchPage/SearchStyle';
import { searchFieldStyles } from './SearchFieldAlt';
import AutocompleteQuery from '../Layout/Header/AutocompleteQuery.gql';
import { useFiltersFromLocationState } from '../CategoryPage/WindowedCategoryPage';
import { ReactComponent as SearchIcon } from '../../svg/Search.svg';
import SearchField from './SearchFieldAlt';

const SearchInputContainerAlt = () => {
  const filtersFromLocationState = useFiltersFromLocationState();

  return (
    <Styled.InputSearchFieldsContainer role="search">
      <Styled.SearchFieldContainer>
        <Styled.SearchIconContainer>
          <SearchIcon />
        </Styled.SearchIconContainer>
        <SearchField
          autocompleteQuery={AutocompleteQuery}
          placeholder="Bildel/Artikelnr/originalnr"
          className={searchFieldStyles}
          aria-label="Sök efter produkter"
          filtersFromLocationState={filtersFromLocationState}
        />
      </Styled.SearchFieldContainer>
    </Styled.InputSearchFieldsContainer>
  );
};

export default SearchInputContainerAlt;
