import React from 'react';
import { styled } from 'linaria/react';
import { theme } from '../Theme';

const ErrorMessageHandlerContainer = styled('div')`
  line-height: 3rem;
  h2 {
    font-size: 18px;
  }

  a {
    text-decoration: none;
  }

  ${theme.below.lg} {
    line-height: 2.5rem;
  }
`;

const ErrorMessageHandler = () => {
  return (
    <ErrorMessageHandlerContainer>
      <h3>Vi hittade inga produkter som matchade dina kriterier</h3>
      <p>
        Men skicka gärna en förfrågan till{' '}
        <a href="mailto:kundservice@allabildelar.se">
          kundservice@allabildelar.se
        </a>{' '}
        för att få hjälp att hitta din bildel
        {` `}
      </p>
      <p>
        Glöm inte att skriv bilens <b>registreringsnummer</b> och vilka{' '}
        <b>bildelar</b> du söker.
      </p>
    </ErrorMessageHandlerContainer>
  );
};

export default ErrorMessageHandler;
