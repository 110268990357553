import React, { useMemo, useEffect, useRef, useState } from 'react';
import { theme } from '../../../Theme';
import { styled } from 'linaria/react';
import t from '@jetshop/intl';

const ButtonWrapper = styled('div')`
  display: flex;
  flex-grow: 1;
`;

const Button = styled('button')`
  position: relative;
  z-index: 9;
  background: black;
  color: white;
  padding: 8px 40px;
  border: none;
  cursor: pointer;
  text-align: left;
  text-transform: uppercase;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  outline: none;
  font-size: 12px;

  p {
    transition: all 0.2s ease;
    padding-right: 10px;
  }
  &.fixed {
    top: 32px;
  }

  ${theme.below.sm} {
    padding: 12px 20px;
  }
`;

const Badge = styled('div')`
  position: absolute;
  display: block;
  top: 50%;
  left: 0;
  background: white;
  color: black;
  padding: 2px 4px;
  border-radius: 4px;
  left: 10px;
  font-size: 0.8rem;
  z-index: 2;
  line-height: 0.9rem;
  transform: translateY(-50%);
`;

const IconWrapper = styled('div')`
  position: absolute;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: auto;
  line-height: 0;
`;

const FilterButton = ({ showTarget, filters, showIcon }) => {
  const buttonWrapperElem = useRef(null);
  const [isFixed, setIsFixed] = useState(false);

  useEffect(() => {
    function scroll() {
      const elemYPos = buttonWrapperElem.current.getBoundingClientRect().y;
      if (elemYPos <= 32) setIsFixed(true);
      else setIsFixed(false);
    }
    window.addEventListener('scroll', scroll);
    return () => {
      window.removeEventListener('scroll', scroll);
    };
  });

  const numOfActiveFilters = useMemo(() => {
    let activeFilters = 0;
    filters?.forEach(filter => {
      switch (filter.__typename) {
        case 'ListFilter':
        case 'MultiListFilter':
          if (filter.hasActiveItems) activeFilters++;
          break;
        case 'NumericRangeFilter':
          if (filter.isActive) activeFilters++;
          break;
        case 'BooleanFilter':
          if (filter.value) activeFilters++;
          break;
        default:
          break;
      }
    });

    return activeFilters;
  }, [filters]);

  return (
    <ButtonWrapper ref={buttonWrapperElem}>
      <Button onClick={showTarget} className={isFixed ? 'fixed' : ''}>
        <IconWrapper>
          {showIcon && numOfActiveFilters > 0 && (
            <Badge>{numOfActiveFilters}</Badge>
          )}
        </IconWrapper>
        Filteralternativ
      </Button>
    </ButtonWrapper>
  );
};

export default FilterButton;
