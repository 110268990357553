import React, { useState } from 'react';
import { theme } from '../Theme';
import { css } from 'linaria';
import { styled } from 'linaria/react';
import t from '@jetshop/intl';
import { TrendLink } from '../ui/Button';
import { Above, Below } from '@jetshop/ui/Breakpoints';

const CarModelContentWrapper = styled('div')`
  display: flex;
  gap: 0.5rem 1.5rem;
  padding: 5px;
  padding-left: 0px;
  align-items: center;
  flex-wrap: wrap;

  ${theme.above.xl} {
    flex-wrap: wrap;
  }

  ${theme.below.sm} {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 0.5rem 0rem;
    padding: 0px;
    padding-left: 0px;
    align-items: flex-start;
  }
`;

const CarModelTitle = styled('p')`
  font-family: 'Barlow';
  font-size: 1rem;
  font-weight: 500;
`;

const CarModelContent = styled('p')`
  margin-left: 0px;
  color: ${theme.colors.black};
  font-size: 1rem;

  ${theme.below.sm} {
    font-size: 0.875rem;
  }
`;

const ButtonWrapper = styled('div')``;

const carModelResetButton = css`
  font-size: 1rem;
  padding: 0.5rem 1rem;
  margin-left: 16px;
  cursor: pointer;
  font-family: 'Barlow';

  ${theme.below.sm} {
    margin-left: 0px;
    padding: 0rem 0rem;
  }
`;

const CarModelSpecification = ({ carData }) => {
  const [showAllData, setShowAllData] = useState(false);

  const toggleDataVisibility = () => {
    setShowAllData(!showAllData);
  };

  return (
    <>
      {carData && (
        <Above breakpoint="sm">
          <CarModelContentWrapper>
            {carData?.nameOfCar && (
              <CarModelContent>
                {t('Name of car')}: <strong>{carData?.nameOfCar}</strong>
              </CarModelContent>
            )}
            {carData?.licensePlateNumber && (
              <CarModelContent>
                {t('Licenseplate Number')}:
                <strong>{carData?.licensePlateNumber}</strong>
              </CarModelContent>
            )}
            {carData?.modelYear && (
              <CarModelContent>
                {t('Model year')}: <strong>{carData?.modelYear}</strong>
              </CarModelContent>
            )}
            {carData?.gearboxText && (
              <CarModelContent>
                {t('Gearbox')}: <strong>{carData?.gearboxText}</strong>
              </CarModelContent>
            )}
            {carData?.aisEngineCode && (
              <CarModelContent>
                {t('Engine code')}: <strong>{carData?.aisEngineCode}</strong>
              </CarModelContent>
            )}
            {carData?.carType && (
              <CarModelContent>
                {t('Car type')}: <strong>{carData?.carType}</strong>
              </CarModelContent>
            )}
            {carData?.color && (
              <CarModelContent>
                {t('Color')}: <strong>{carData?.color}</strong>
              </CarModelContent>
            )}
            {carData?.bodyStyle && (
              <CarModelContent>
                {t('Body style')}: <strong>{carData?.bodyStyle}</strong>
              </CarModelContent>
            )}
            {carData?.fuelText && (
              <CarModelContent>
                {t('Fuel')}: <strong>{carData?.fuelText}</strong>
              </CarModelContent>
            )}
          </CarModelContentWrapper>
        </Above>
      )}
      {carData && (
        <Below breakpoint="sm">
          <>
            <CarModelTitle>Valt fordon:</CarModelTitle>
            <CarModelContentWrapper>
              {carData?.nameOfCar && (
                <CarModelContent>
                  {t('Name of car')}: <strong>{carData?.nameOfCar}</strong>
                </CarModelContent>
              )}
              {carData?.licensePlateNumber && (
                <CarModelContent>
                  {t('Licenseplate Number')}:
                  <strong>{carData?.licensePlateNumber}</strong>
                </CarModelContent>
              )}
              {carData?.modelYear && (
                <CarModelContent>
                  {t('Model year')}: <strong>{carData?.modelYear}</strong>
                </CarModelContent>
              )}
              {carData?.gearboxText && (
                <CarModelContent>
                  {t('Gearbox')}: <strong>{carData?.gearboxText}</strong>
                </CarModelContent>
              )}
              {!showAllData && (
                <ButtonWrapper>
                  <p
                    className={carModelResetButton}
                    onClick={toggleDataVisibility}
                  >
                    Visa mer
                  </p>
                </ButtonWrapper>
              )}
              {showAllData && (
                <>
                  {carData?.gearboxText && (
                    <CarModelContent>
                      {t('Gearbox')}: <strong>{carData?.gearboxText}</strong>
                    </CarModelContent>
                  )}
                  {carData?.aisEngineCode && (
                    <CarModelContent>
                      {t('Engine code')}:{' '}
                      <strong>{carData?.aisEngineCode}</strong>
                    </CarModelContent>
                  )}
                  {carData?.carType && (
                    <CarModelContent>
                      {t('Car type')}: <strong>{carData?.carType}</strong>
                    </CarModelContent>
                  )}
                  {carData?.color && (
                    <CarModelContent>
                      {t('Color')}: <strong>{carData?.color}</strong>
                    </CarModelContent>
                  )}
                  {carData?.bodyStyle && (
                    <CarModelContent>
                      {t('Body style')}: <strong>{carData?.bodyStyle}</strong>
                    </CarModelContent>
                  )}
                  {carData?.fuelText && (
                    <CarModelContent>
                      {t('Fuel')}: <strong>{carData?.fuelText}</strong>
                    </CarModelContent>
                  )}
                  <ButtonWrapper>
                    <p
                      className={carModelResetButton}
                      onClick={toggleDataVisibility}
                    >
                      Visa färre
                    </p>
                  </ButtonWrapper>
                </>
              )}
            </CarModelContentWrapper>
          </>
        </Below>
      )}
    </>
  );
};

export default CarModelSpecification;
