import React from 'react';
import { styled } from 'linaria/react';
import { css, cx } from 'linaria';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import ContentLoader from 'react-content-loader';
import { ReactComponent as Carot } from '@jetshop/ui/svg/Carrot.svg';
import { theme } from '../Theme';
import Accordion from '@jetshop/ui/Accordion/Accordion';
import { useListFilter } from '@jetshop/core/hooks/Filters/useListFilter';
import { getSearchParamsBasedOnCategoryId } from '../../helpers';

import AllCategories from './AllCategories.gql';
import { useQuery } from '@apollo/react-hooks';

const CategoryListingContainer = styled.div`
  height: 1000px;
  overflow-y: auto;
`;

const CategoryListingWrapper = styled.div`
  position: sticky;
  top: 0;
  padding-right: 1rem;
  ${theme.below.sm} {
    padding-right: 0;
    padding: 1rem;
  }
`;

const CategoryLink = styled(Link)`
  text-decoration: none;
  font-family: ${theme.fonts.primary};
  font-weight: ${props => (props.level <= 2 || props.isActive ? 700 : 400)};
  font-size: 14px;
  color: ${props =>
    props.isActive ? theme.colors.abdGreen : theme.colors.black};
  text-overflow: ellipsis;
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;

  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &:hover {
    color: ${theme.colors.abdGreen};
  }
`;

const AccordionHeader = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Category = styled.div`
  display: block;
  padding-left: ${props => (props.level ? theme.space[props.level - 2] : 0)};
`;

const carot = css`
  cursor: pointer;
  transition: transform 200ms;
  transform: rotate(-90deg);
  margin-left: 0.35rem;
  &.is-open {
    transform: rotate(0);
  }
`;

const Button = styled('button')`
  background-color: transparent;
  font-family: ${theme.fonts.primary};
  font-weight: ${props => (props.level <= 2 || props.isActive ? 700 : 400)};
  font-size: 14px;
  color: ${props =>
    props.isActive ? theme.colors.abdGreen : theme.colors.black};

  &:hover {
    color: ${theme.colors.abdGreen};
  }
`;

const CategoryListingTitle = styled.h2`
  font-family: ${theme.fonts.primary};
  font-weight: ${props => (props.level > 2 ? 400 : 700)};
  text-transform: uppercase;
`;

const Loading = props => (
  <ContentLoader
    speed={2}
    width={400}
    height={400}
    viewBox="0 0 400 400"
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
    {...props}
  >
    <rect x="0" y="15" rx="5" ry="5" width="220" height="10" />
    <rect x="0" y="45" rx="5" ry="5" width="220" height="10" />
    <rect x="0" y="75" rx="5" ry="5" width="220" height="10" />
    <rect x="0" y="105" rx="5" ry="5" width="220" height="10" />
    <rect x="0" y="135" rx="5" ry="5" width="220" height="10" />
    <rect x="0" y="165" rx="5" ry="5" width="220" height="10" />
    <rect x="0" y="195" rx="5" ry="5" width="220" height="10" />
    <rect x="0" y="225" rx="5" ry="5" width="220" height="10" />
    <rect x="0" y="255" rx="5" ry="5" width="220" height="10" />
    <rect x="0" y="285" rx="5" ry="5" width="220" height="10" />
    <rect x="0" y="315" rx="5" ry="5" width="220" height="10" />
    <rect x="0" y="345" rx="5" ry="5" width="220" height="10" />
    <rect x="0" y="375" rx="5" ry="5" width="220" height="10" />
    <rect x="0" y="405" rx="5" ry="5" width="220" height="10" />
    <rect x="0" y="435" rx="5" ry="5" width="220" height="10" />
    <rect x="0" y="465" rx="5" ry="5" width="220" height="10" />
    <rect x="0" y="495" rx="5" ry="5" width="220" height="10" />
    <rect x="0" y="525" rx="5" ry="5" width="220" height="10" />
  </ContentLoader>
);

const sortByName = (a, b) => {
  const nameA = a.name.toUpperCase();
  const nameB = b.name.toUpperCase();
  if (nameA < nameB) {
    return -1;
  }
  if (nameA > nameB) {
    return 1;
  }

  return 0;
};

export const CategoryList = ({
  filter,
  filtersFromLocationState,
  currentCategory,
  onClick
}) => {
  const { apply, clear } = useListFilter({ filter });

  const handleFilterClick = ({ value }) => {
    clear();
    apply({ value });
    if (typeof onClick === 'function') {
      onClick();
    }
  };

  const { data: allCategories, loading: allCategoriesLoading } = useQuery(
    AllCategories,
    {
      variables: { categoryId: 2 }
    }
  );

  if (allCategoriesLoading) {
    return <Loading />;
  }

  return (
    <CategoryListingContainer>
      <CategoryListingWrapper>
        <CategoryListingTitle>Kategorier</CategoryListingTitle>
        <Accordion
          single
          initialOpenIndexes={
            currentCategory
              ? [
                  currentCategory.level > 2
                    ? currentCategory?.parent?.id
                    : currentCategory.id
                ]
              : []
          }
        >
          {accordionProps => {
            const { AccordionContent } = accordionProps;

            return [...allCategories?.category?.subcategories]
              .sort(sortByName)
              .map((category, index) => {
                const isOpen = accordionProps.openIndexes.includes(category.id);

                return (
                  <Category
                    key={`${category.id}-${index}`}
                    level={category.level}
                  >
                    <AccordionHeader>
                      <CategoryItem
                        category={category}
                        filter={filter}
                        filtersFromLocationState={filtersFromLocationState}
                        handleFilterClick={handleFilterClick}
                      />
                      {category?.hasSubcategories && (
                        <div
                          onClick={() =>
                            accordionProps.handleClick(category.id)
                          }
                        >
                          <Carot
                            className={cx(carot, isOpen ? 'is-open' : null)}
                          />
                        </div>
                      )}
                    </AccordionHeader>
                    <AccordionContent isOpen={isOpen}>
                      <SubcategoryList
                        isOpen={isOpen}
                        openIndexes={accordionProps.openIndexes}
                        filtersFromLocationState={filtersFromLocationState}
                        filter={filter}
                        handleFilterClick={handleFilterClick}
                      />
                    </AccordionContent>
                  </Category>
                );
              });
          }}
        </Accordion>
      </CategoryListingWrapper>
    </CategoryListingContainer>
  );
};

function SubcategoryList({
  isOpen,
  openIndexes,
  filtersFromLocationState,
  filter,
  handleFilterClick
}) {
  const categoryId = openIndexes.at(-1);

  const { data, loading } = useQuery(AllCategories, {
    variables: { categoryId },
    skip: !isOpen || !categoryId
  });

  if (loading) {
    return <Loading />;
  }

  if (!data) {
    return null;
  }

  return [...data?.category?.subcategories]
    .sort(sortByName)
    .map((subcategory, index) => {
      return (
        <Category key={`${subcategory.id}-${index}`} level={subcategory.level}>
          <CategoryItem
            category={subcategory}
            filter={filter}
            filtersFromLocationState={filtersFromLocationState}
            handleFilterClick={handleFilterClick}
          />
        </Category>
      );
    });
}

function CategoryItem({
  category,
  filter,
  filtersFromLocationState,
  handleFilterClick
}) {
  const location = useLocation();

  const filterItem = filter?.items?.find(item => item.text === category.name);
  const searchParams = getSearchParamsBasedOnCategoryId(category);
  const isActive = location.pathname.indexOf(category.primaryRoute.id) > -1;

  return !filterItem ? (
    <CategoryLink
      to={{
        pathname: `/${category.primaryRoute.id}${searchParams}`,
        state: {
          recopartCarModel: filtersFromLocationState
        }
      }}
      isActive={isActive}
      level={category.level}
    >
      <span>{category.name}</span>
      <span>{category.products.totalResults}</span>
    </CategoryLink>
  ) : (
    <Button onClick={() => handleFilterClick(filterItem)}>
      {category.name}
    </Button>
  );
}
