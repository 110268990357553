import React from 'react';
import ArticleGrid from '../../elements/root/ArticleBlock/ArticleGrid';
import ArticleCarousel from '../../elements/root/ArticleBlock/ArticleCarousel';
import { styled } from 'linaria/react';
import { theme } from '../Theme';
import { Above } from '@jetshop/ui/Breakpoints';
import FullWidth from '../../components/Layout/FullWidth';
import MaxWidth from '../../components/Layout/MaxWidth';
import useChildren from '../../hooks/useChildren';
import ContentGrid from './ContentGrid';
import ContentCarousel from './ContentCarousel';

const InnerWidth = styled('div')`
  display: block;
  flex-direction: column;
  justify-content: stretch;
  align-items: stretch;
  margin: 0 auto;
  padding: 0 1rem;
  position: relative;
  flex: 1 1 auto;
  width: 100%;
  max-width: 75rem;
  ${theme.above.sm} {
    padding: 0 2rem;
  }
`;

const ArticleBlockWrapper = styled('div')`
  background-color: ${theme.colors.white};
`;

const ArticleBlockContainer = styled('div')`
  color: ${theme.colors.abdDarkGrey};
  height: auto;
  width: 100%;
  font-family: 'Barlow';
  font-style: normal;
  margin-bottom: 40px;

  ${theme.below.sm} {
    margin-bottom: 30px;
  }
`;

const ContentBlock = props => {
  console.log('props', props);
  const { title } = props;

  const { smallArticle } = useChildren(props);
  console.log('small article', smallArticle);
  if (!Array.isArray(smallArticle) || !smallArticle?.length > 0) return null;

  const hasRoute = article => article.linkUrlAlt;
  const articles = smallArticle.filter(hasRoute);

  return (
    <ArticleBlockWrapper>
      <InnerWidth>
        <ArticleBlockContainer>
          <Above breakpoint="md">
            {matches =>
              matches ? (
                <ContentGrid articles={articles} title={title} />
              ) : (
                <FullWidth className="below-xl">
                  <ContentCarousel articles={articles} title={title} />
                </FullWidth>
              )
            }
          </Above>
        </ArticleBlockContainer>
      </InnerWidth>
    </ArticleBlockWrapper>
  );
};

export default ContentBlock;
