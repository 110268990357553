import { css } from 'linaria';
import { styled } from 'linaria/react';
import { Link } from 'react-router-dom';
import ImportedMaxWidth from '../../components/Layout/MaxWidth';
import ImportedSpinner from '../../components/ui/Spinner';
import { theme } from '../Theme';

const searchFieldStyles = css`
  margin: 0;

  ${theme.below.md} {
    flex: 1;
  }

  &[data-flight-searchfield] > * {
    display: flex;
    height: 100%;
    width: 100% !important;
    justify-content: center;

    input {
      font-family: 'Barlow';
      font-weight: 700;
      font-size: 24px;
      line-height: 120%;
      background-color: #ffffff;
      border-color: hsl(0, 0%, 80%);
      height: 100%;
      width: 100%;
      padding: 0 1rem;

      ::placeholder {
        text-transform: none;
      }

      ${theme.below.md} {
        font-size: 16px;
      }
    }
  }
  [data-flight-searchfield-cancel] {
    display: none;
  }
  [data-flight-searchfield-flyout] {
    text-align: left;
    top: 100%;

    > * {
      padding: 0.5rem;
    }
    h2 {
      color: #999999;
      text-transform: uppercase;
      font-size: 0.75rem;
      padding: 0.5rem;
    }
    li {
      border-top: 1px solid #e8e8e8;
    }
    a {
      color: black;
      padding: 0.5rem;
      display: block;
    }
  }
`;

const HeaderWrapper = styled('div')`
  background-color: ${theme.colors.abdLightGrey};
  font-family: 'Barlow';
`;

const MaxWidth = styled(ImportedMaxWidth)`
  ${theme.below.md} {
    padding: 0;
  }
`;

const RegNrContainer = styled('div')`
  margin: 30px 0;
  width: 100%;
  position: relative;

  ${theme.below.md} {
    margin-top: 0;
  }
`;

const ImageContainer = styled('div')`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
`;

const TextContainer = styled('div')`
  position: relative;
  z-index: 1;
  color: ${theme.colors.white};
  padding: 80px 40px 0;
  width: ${props => (props.columns === 2 ? '65%' : '45%')};

  ${theme.below.xl} {
    width: ${props => (props.columns === 2 ? '100%' : '60%')};
  }

  ${theme.below.lg} {
    padding-top: ${props => (props.columns === 2 ? '40px' : '80px')};
  }

  ${theme.below.md} {
    width: 100%;
    padding: 80px 2rem 20px;
  }

  ${theme.below.sm} {
    padding: 80px 1rem 20px;
  }
`;

const Title = styled('h1')`
  font-weight: 800;
  font-size: 60px;
  line-height: 110%;
  margin-bottom: 20px;
  max-width: 90%;

  span {
    color: ${theme.colors.abdGreen};
  }

  ${theme.below.lg} {
    font-size: 36px;
    line-height: 39px;
  }
`;

const InfoText = styled('div')`
  font-weight: 600;
  font-size: 20px;
  line-height: 140%;
  margin-bottom: 40px;

  ${theme.below.lg} {
    font-size: 16px;
  }
`;

const RegNumber = styled('div')`
  position: relative;
  z-index: 1;
  font-weight: 700;
  font-size: 24px;
  line-height: 75%;
  max-height: 60px;
  display: flex;
  align-items: center;
  gap: 10px;
  background-color: ${theme.colors.white};
  padding-right: 5px;
`;

const RegIcon = styled('div')`
  background-color: #062d93;

  display: flex;
  align-items: flex-end;
  justify-content: center;
  color: ${theme.colors.white};
  font-size: 16px;

  ${theme.below.md} {
    padding: 0 9px;
  }
`;

const RegNr = styled('div')`
  font-weight: 700;
  font-size: 24px;
  line-height: 120%;
  color: ${theme.colors.abdRegGrey};
  width: 80px;

  ${theme.below.md} {
    font-size: 16px;
    width: 90px;
  }
`;

const RegNrSuggestion = styled(Link)`
  position: absolute;
  top: 100%;
  left: 32px;
  right: 0;
  background: white;
  padding: 10px;
  text-transform: none;
  font-size: 16px;
  border: 1px solid ${theme.colors.abdBadgeGrey};
  text-decoration: none;
  color: ${theme.colors.abdDarkGrey};

  ${theme.below.md} {
    left: 50px;
  }

  span {
    display: block;
    font-size: 12px;
    font-weight: 400;
    font-style: italic;
  }

  :hover {
    background-color: ${theme.colors.abdBadgeGrey};
    text-decoration: underline;
  }
`;

const RegInfoInput = styled('input')`
  font-family: 'Barlow';
  font-weight: 700;
  font-size: 24px;
  text-transform: uppercase;
  line-height: 120%;
  width: 200px;

  border: 0;
  color: ${theme.colors.abdDarkGrey};

  :focus {
    outline: none;
  }

  ::placeholder {
    text-transform: none;
  }

  ${theme.below.md} {
    font-size: 16px;
    height: 40px;
    width: 100%;
  }
`;

const SpanWrapper = styled('div')`
  display: flex;
  align-items: center;

  ${theme.below.md} {
    justify-content: center;
  }
`;

const SpanText = styled('span')`
  font-family: 'Barlow';
  font-weight: 700;
  font-size: 18px;
  color: ${theme.colors.white};

  ${theme.below.md} {
    font-size: 18px;
  }

  ${theme.below.sm} {
    font-size: 16px;
  }
`;

const ButtonContainer = styled('div')`
  display: inline-block;

  ${theme.below.sm} {
    display: block;
  }
`;

const InputFieldsContainer = styled('form')`
  display: flex;
  align-items: stretch;
  gap: 20px;

  padding: 40px 40px 80px;

  position: relative;

  input:focus {
    outline: none;
  }

  ${theme.below.lg} {
    padding: 0 2rem 40px;
  }

  ${theme.below.md} {
    padding: 0 2rem 40px;
    flex-direction: column;
  }

  ${theme.below.sm} {
    padding: 0 1rem 40px;
  }
`;

const InputSearchFieldsContainer = styled(InputFieldsContainer)`
  padding: 0px;
`;

const SearchFieldContainer = styled('div')`
  display: flex;
  height: 44px;
`;
const SearchIconContainer = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  background-color: ${theme.colors.abdDarkGrey};

  svg {
    width: 30px;
    height: 30px;

    path {
      stroke: white;
    }
  }
`;

const Spinner = styled(ImportedSpinner)`
  position: absolute;
  right: 28px;
  width: 15px;
  height: 15px;

  ${theme.above.md} {
    right: 32px;
    width: 20px;
    height: 20px;
  }

  circle.path {
    stroke: ${theme.colors.abdGreen};
  }
`;

const Styled = {
  searchFieldStyles,
  HeaderWrapper,
  MaxWidth,
  RegNr,
  RegNrContainer,
  RegIcon,
  RegNumber,
  RegInfoInput,
  RegNrSuggestion,
  ImageContainer,
  TextContainer,
  Title,
  InfoText,
  ButtonContainer,
  InputFieldsContainer,
  InputSearchFieldsContainer,
  SearchFieldContainer,
  SearchIconContainer,
  Spinner,
  SpanWrapper,
  SpanText
};

export default Styled;
