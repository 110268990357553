import ErrorBoundary from '@jetshop/ui/ErrorBoundary/Generic';
import PassThrough from '@jetshop/ui/ErrorBoundary/PassThrough';

import React, { useEffect, useMemo } from 'react';
import { useLazyQuery } from '@apollo/react-hooks';
import GetCategoryFilters from './CategoryFilterQuery.gql';
import Select from '../../../components/ui/Select';
import { useRegNrContext, useRegNrDispatch } from './Context';

export const useMultiListFilter = () => {
  const dispatch = useRegNrDispatch();
  const [sendQuery, { error, data: filterData }] = useLazyQuery(
    GetCategoryFilters,
    {
      fetchPolicy: 'network-only', // Used for first execution
      nextFetchPolicy: 'network-only' // Used for subsequent executions
    }
  );

  const getQueryVariables = filterVariable => ({
    id: '2',
    first: 1,
    offset: 0,
    orderBy: null,
    orderByDirection: 'DESCENDING',
    filters: {
      multiListFilters: filterVariable
    }
  });

  const filters = filterData?.category?.products?.filters;

  const [multiListFilter = {}] = useMemo(
    () =>
      filters?.filter(filter => filter.__typename === 'MultiListFilter') || [],
    [filters]
  );

  const [manufacturer = {}, model = {}] = multiListFilter?.lists || [];

  const sendFilterQuery = (filterVariable = []) => {
    const variables = getQueryVariables(filterVariable);
    sendQuery({ variables });
  };

  useEffect(() => {
    if (manufacturer?.items?.length > 0) {
      dispatch({
        type: 'addFilter',
        filter: {
          filterId: multiListFilter.id,
          type: 'manufacturer',
          ...manufacturer
        }
      });
    }
  }, [manufacturer, dispatch]);

  useEffect(() => {
    if (model?.items?.length > 0) {
      dispatch({
        type: 'addFilter',
        filter: {
          filterId: multiListFilter.id,
          type: 'model',
          ...model
        }
      });
    }
  }, [model, dispatch]);

  return { sendFilterQuery };
};

export function MultiListFilters() {
  const { sendFilterQuery } = useMultiListFilter();
  const context = useRegNrContext();

  useEffect(() => {
    sendFilterQuery();
  }, []);

  return (
    <ErrorBoundary component={PassThrough}>
      <MultiListFilter
        filterId={context.id}
        filter={context.manufacturer}
        index={0}
      />
      <MultiListFilter filterId={context.id} filter={context.model} index={1} />
    </ErrorBoundary>
  );
}

const colorStyles = {
  control: {
    width: '100%'
  },
  container: {
    width: 'calc(100% - 60px)'
  }
};

function MultiListFilter({ filter, filterId, index }) {
  let updatedFilter = { ...filter }; // Create a copy of the filter object

  if (updatedFilter?.name === 'Tillverkare') {
    updatedFilter.name = 'Bilmärke';
  }

  const { sendFilterQuery } = useMultiListFilter();
  const dispatch = useRegNrDispatch();
  const options = filter?.items
    ?.filter(item => item.resultCount > 0)
    ?.map(item => ({
      ...item,
      label: item.text
    }));

  return (
    <Select
      extendedStyles={colorStyles}
      instanceId={index}
      // placeholder={filter?.name}
      placeholder={updatedFilter?.name}
      options={options}
      value={filter?.activeItem || ''}
      onChange={item => {
        if (item.resultCount > 0) {
          if (filter.type === 'manufacturer') {
            sendFilterQuery([{ id: filterId, values: [item.value] }]);
            dispatch({ type: 'resetModel' });
          }
          localStorage.removeItem('licensePlate');
          dispatch({
            type: 'addActiveFilterItem',
            filter: { ...filter, activeItem: item }
          });
        }
      }}
    />
  );
}

// [
//   {
//     id: '2:fits-car',
//     values: ['Audi']
//   }
// ];
