import React from 'react';
import { styled } from 'linaria/react';
import { theme } from '../../../components/Theme';
import MaxWidth from '../../../components/Layout/MaxWidth';
import useCleanProps from '../../../hooks/useCleanProps';
import Campaign from '../../../components/ui/Campaign';

const CampaignBlockWrapper = styled('div')`
  background-color: ${theme.colors.white};
`;

const StyledMaxWidth = styled(MaxWidth)`
  ${theme.below.md} {
    padding: 0;
  }
`;

const CampaignBlock = props => {
  const cleanProps = useCleanProps(props);

  return (
    <CampaignBlockWrapper>
      <StyledMaxWidth>
        <Campaign {...cleanProps} />
      </StyledMaxWidth>
    </CampaignBlockWrapper>
  );
};

export default CampaignBlock;
