import { useListFilter } from '@jetshop/core/hooks/Filters/useListFilter';
import { ReactComponent as Cross } from '../../../assets/svg/cross.svg';
import React from 'react';
import { styled } from 'linaria/react';

import { theme } from '../../Theme';

export const FilterGroup = styled('div')`
  font-size: 1rem;
`;
const Label = styled('span')`
  font-weight: 300;
  padding-right: 0.25rem;
`;

const Value = styled('span')`
  font-weight: 600;
  padding-right: 1rem;
`;

const StyledCross = styled(Cross)`
  width: 14px;
  height: 14px;
  border-radius: 10px;
  background-color: #646464;
  padding: 2px;
`;

export const AppliedFilter = styled('button')`
  color: ${theme.colors.black};
  border: 1px solid black;
  font-size: 0.875rem;
  background-color: ${theme.colors.white};
  border-radius: 0px;
  height: 26px;
  padding: 0 10px;

  &:hover {
    ${StyledCross} {
      background-color: ${theme.colors.red};
    }
  }
`;

export const FilterWrapper = styled('aside')`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
`;

export const FilterTitle = styled('div')`
  display: block;
  width: 100%;
  font-family: 'Barlow';
  font-size: 1rem;
  font-weight: 500;
`;

export const ActiveFilters = ({ filters }) => {
  if (!filters) return null;

  const activeListFilters = filters?.filter(listFilter => {
    return listFilter.hasActiveItems && listFilter.name !== 'carCode';
  });

  if (activeListFilters.length === 0) return null;

  return (
    <FilterWrapper>
      <FilterTitle>
        <p>Applicerat filter:</p>
      </FilterTitle>
      {activeListFilters.map(listFilter => {
        return (
          <FilterGroup key={listFilter.id}>
            {listFilter.items
              .filter(item => item.isActive)
              .map(listFilterItem => (
                <ActiveListFilterItem
                  item={listFilterItem}
                  listFilter={listFilter}
                  key={listFilterItem.value}
                />
              ))}
          </FilterGroup>
        );
      })}
    </FilterWrapper>
  );
};

const ActiveListFilterItem = ({ item, listFilter }) => {
  const { apply } = useListFilter({ filter: listFilter });
  return (
    <AppliedFilter
      type="button"
      key={item.value}
      onClick={() => {
        apply({ value: item.value });
      }}
    >
      <Label>{listFilter.name} </Label>
      <Value>{item.text}</Value>
      <StyledCross />
    </AppliedFilter>
  );
};
